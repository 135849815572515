import React, { Component } from 'react';
import { isNil, isEqual, get } from 'lodash';

import { Overlay } from 'src/common-ui/index';
import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { WorklistProps, WorklistState } from 'src/pages/Worklist/Worklist.types';
import CompanionList from 'src/components/CompanionList/CompanionList';
import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import WorklistViewContainer from 'src/pages/Worklist/WorklistViewContainer';
import { parseCompanionListViewConfig } from 'src/utils/Component/ListView';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { classes } from 'typestyle';
import { worklistCompanionViewContainer, worklistViewContainer } from './Worklist.styles';
import { FabType } from 'src/components/higherOrder/withFab';
import { Route, Routes } from 'react-router-dom';
import { GroupViewItem } from 'src/services/configuration/codecs/viewdefns/general';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export default class Worklist extends Component<WorklistProps, WorklistState> {
  constructor(props: WorklistProps) {
    super(props);

    this.state = {
      selectedCompanionItem: undefined,
      useStyleColors: true,
      viewMounted: false,
      sortDirection: 'desc',
    };
  }

  componentDidMount() {
    this.props.onShowView();
    this.setState({
      viewMounted: true,
    });
  }

  getLevelField = () => {
    return this.props.selectedLevel?.dataIndex;
  };

  onFabClick = () => {
    switch (this.props.fab.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  handleStyleSelection = (style: ListViewable | undefined, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const levelField = this.props.selectedLevel?.dataIndex;
    if (levelField == null && style == null) {
      return;
    } else if (style == null) {
      return;
    }

    // if the selected item is the same, and isn't from a true mouse click, ignore it
    // as the companion list can trigger reselections when the listdata call refires
    if (this.state.selectedCompanionItem && isEqual(style.id, this.state.selectedCompanionItem.id) && !event) {
      return;
    }

    this.props.selectItem(style.id);
    // if we get the same item id selected, and it's from a true click event
    // select undef, the reselect the clicked item
    // this breaks memoization of the selection without having to mangle the
    // underlying reselection protections
    if (this.state.selectedCompanionItem && isEqual(style.id, this.state.selectedCompanionItem.id) && event) {
      this.setState(
        {
          selectedCompanionItem: undefined,
        },
        () => {
          this.setState({
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: levelField ? `${levelField}:id` : 'id',
                value: style.id,
              },
            },
            selectedCompanionItem: style,
          });
        }
      );
    } else {
      // the normal codepath
      this.setState({
        companionScrollTo: {
          eventId: Date.now(),
          where: {
            key: levelField ? `${levelField}:id` : 'id',
            value: style.id,
          },
        },
        selectedCompanionItem: style,
      });
    }
  };

  handleSortChange = (sortField: string) => {
    this.setState({ sortField });
  };

  handleSortDirectionChange = (sortDirection: SortByDirection) => {
    this.setState({ sortDirection });
  };

  handleLevelChange = (levelField: string) => {
    const { config } = this.props;
    const levelBys: GroupViewItem[] = get(config, 'companionView.levelBy.view', []);
    const selectedLevel = levelBys.find((i) => i.dataIndex === levelField);
    if (selectedLevel == null) return; // this is error state.
    if (selectedLevel.dataIndex !== this.props.selectedLevel?.dataIndex) {
      this.props.selectLevel(selectedLevel);
    }
    this.setState({
      selectedCompanionItem: undefined,
    });
  };

  render() {
    const {
      isLoading,
      config,
      subheaderSlice,
      subheaderViewDefns,
      styles,
      flowStatus,
      updateFlowStatus,
      flowStatusConfig,
      showFlowStatus,
      onShowView,
      updateCompanionSearchString,
      updateStyleItem,
      companionSearch,
      adornments,
      showUndoBtn,
    } = this.props;
    const { selectedCompanionItem } = this.state;
    const levelField = this.props.selectedLevel?.dataIndex;

    if (isLoading || isNil(config)) {
      return <Overlay type="loading" visible={true} />;
    }
    const defaultTabIndex = config.tabs.findIndex((tab) => tab.componentType === config.defaultTab);

    if (!this.state.viewMounted) {
      return <div />;
    }
    const mappings = config.companionView.itemMappings;
    const dataLookup = parseCompanionListViewConfig((mappings as unknown) as TenantConfigViewData);

    const clSelectedItem = selectedCompanionItem
      ? styles.find((x) => x[dataLookup.titleId] == selectedCompanionItem.id)
      : undefined;

    const content = (
      <div className={classes(listPairStyle, worklistViewContainer)}>
        <div className="data-container" data-qa="worklist-view">
          <div className={worklistCompanionViewContainer}>
            <CompanionList
              config={config.companionView}
              styles={styles}
              isDataloaded={!isLoading}
              renderSearchComponent={true}
              search={companionSearch}
              levelField={levelField}
              scrollTo={this.state.companionScrollTo}
              onSearchChange={updateCompanionSearchString}
              onSelectStyle={this.handleStyleSelection}
              onLevelChange={this.handleLevelChange}
              onSortChange={this.handleSortChange}
              onSortDirectionChange={this.handleSortDirectionChange}
              flowStatus={flowStatus}
              flowStatusOptions={flowStatusConfig}
              onFilterChange={updateFlowStatus}
              renderFilterComponent={showFlowStatus}
              selectedCompanionItem={clSelectedItem}
            />
          </div>
          <WorklistViewContainer
            adornments={adornments}
            defaultTabIndex={defaultTabIndex >= 0 ? defaultTabIndex : 0}
            // @ts-ignore
            tabsConfig={config.tabs}
            selectedCompanionItem={selectedCompanionItem}
            subheaderSlice={subheaderSlice}
            subheaderViewDefns={subheaderViewDefns}
            styles={styles}
            onViewRefresh={onShowView}
            onStyleItemUpdate={updateStyleItem}
            showUndoBtn={showUndoBtn}
          />
        </div>
      </div>
    );

    return (
      <Routes>
        {config.tabs.map((tab) => (
          <Route key={tab.pathSlot} path={`${tab.pathSlot}`} element={content} />
        ))}
      </Routes>
    );
  }
}
