import type { CellClickedEvent, GridApi, MenuItemDef, GridReadyEvent, ColumnApi, IRowNode, RowSelectedEvent } from '@ag-grid-community/core';
import { CompanionListView } from 'src/common-ui/index';
import { Props as CompanionProps, ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import {
  mapValues,
  findIndex,
  isNil,
  parseInt,
  isEmpty,
  isEqual,
  omitBy,
  isBoolean,
  noop,
  concat,
  debounce,
  set,
  intersection,
  uniqBy,
  get,
} from 'lodash';
import React from 'react';
import { resolvePath } from 'src/cdn';

import { Overlay } from 'src/common-ui/index';
import {
  companionStyles,
  styles,
  extraRowContainerStyles,
  listPairStyle,
  gridContainerStyle,
  macrosContainer,
  marcosRowAndButtonContainer,
  buttonMacroContainer,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import {
  ConfigurableGridState,
  ConfigurableGridProps,
  MassColumnUpdateParams,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { StyledAccordion } from 'src/components/StyledAccordion/StyledAccordion';
import { isNumber } from 'lodash/fp';
import Subheader from 'src/components/Subheader/Subheader.container';
import { SubheaderOwnProps, SubheaderActionButtonProps } from 'src/components/Subheader/Subheader.types';
import ArrowValueRenderer, {
  ArrowValueRendererProps,
  ARROWDIRECTIONS,
} from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import ServiceContainer from 'src/ServiceContainer';
import { simpleByField } from 'src/utils/Pivot/Sort';
import Renderer from 'src/utils/Domain/Renderer';
import { toast } from 'react-toastify';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { BasicItem as PivotBasicItem } from 'src/worker/pivotWorker.types';
import { logError } from 'src/services/loggingService';
import { FabType } from '../higherOrder/withFab';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';
import SplitButton from 'src/common-ui/components/SplitButton/SplitButton';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { makeValidValuesCache } from 'src/services/validValuesCache';
import { getWorklistMultiselectMenuItems, iconClassToString } from '../WorklistContextMenu/WorklistContextMenu';
import { ConfigurableGridButton, TopAttribute } from 'src/services/configuration/codecs/viewdefns/general';
import ConfigureModal, { ConfigureModalProps, Option } from '../Configure/ConfigureModal';
import { EditableGrid, EditableGridProps } from './EditableGrid/EditableGrid';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { formatConfigurableActionItem } from './utils/ConfigurableGrid.utils';
import MetricLineGraph from 'src/components/Visualize/MetricLineGraph/MetricLineGraph';
import { ActionModal } from './utils/ActionModal';
import { IS_PUBLISHED } from 'src/utils/Domain/Constants';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';

const noImage = resolvePath(noImagePath);

export class ConfigurableGrid extends React.Component<ConfigurableGridProps, ConfigurableGridState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  selectPopupLeft!: number;
  selectPopupTop!: number;
  allowEnterList: (string | undefined)[] = [];

  constructor(props: ConfigurableGridProps) {
    super(props);

    this.state = {
      gridRdyCnt: 0,
      companionCollapsed: false,
      companionSortDirection: 'desc',
      companionSortField: undefined,
      activeStyleColor: '',
      notificationModal: false,
      actionType: undefined,
      validValuesCache: makeValidValuesCache(),
      isMfpScopeLoading: false
    };
  }

  componentDidUpdate(prevProps: ConfigurableGridProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.state.validValuesCache.clear();
    }

    if (!isEqual(prevProps.favoritesList, this.props.favoritesList)) {
      const activeFavorite = this.props.favoritesList.find((x) => x.active === true);
      if (activeFavorite && activeFavorite.jsonBlob) {
        if (activeFavorite.jsonBlob.companionData) {
          const compData = activeFavorite.jsonBlob.companionData;
          this.setState({
            companionSortField: compData.companionSortField,
            companionSortDirection: compData.companionSortDirection,
            companionCollapsed: compData.companionCollapsed,
          });
        }
        if (activeFavorite.jsonBlob.groupBySelection && this.props.groupByDropdownProps) {
          const nextSelectedIndex = activeFavorite.jsonBlob.groupBySelection || 0;
          const dropdownData: ConfigurableGridGroupBySelection = {
            selectedIndex: nextSelectedIndex,
            option: this.props.groupByDropdownProps.options[nextSelectedIndex],
          };

          const selectedIndex = this.props.groupByDropdownProps?.selection;
          if (nextSelectedIndex !== selectedIndex) {
            this.props.setGroupBySelection(dropdownData);
          }
        }
      }
    }
  }

  onUpdateConfig = (config: any) => {
    if (config.isDefault) {
      this.setState({
        companionSortDirection: 'desc',
        companionCollapsed: false,
        companionSortField: this.props.defaultCompanionSortField,
      });
    }

    this.props.onUpdateConfig(config);
  };

  onFabClick = () => {
    switch (this.props.fab.fabType) {
      case FabType.cart:
        if (isNil(this.props.assortmentCartLink)) {
          return;
        }
        this.props.addSelectedItemsToCart();
        this.props.routerNavigate(this.props.assortmentCartLink);
        break;
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  generateCompanionViewData = (data: BasicPivotItem[]) => {
    const { identifier } = this.props;
    const companionSortField =
      (this.state.companionSortField ? this.state.companionSortField : this.props.defaultCompanionSortField) || '';
    const companionSortDirection = this.state.companionSortDirection;
    const removedGroupData = data.filter((dat) => {
      // Groups only have one attribute and one meta property in the object: group and id and gridasyncstate
      return Object.keys(dat).length > 3;
    });

    const sortedData = simpleByField(removedGroupData, companionSortField, companionSortDirection);
    const compData = uniqBy(sortedData.map((d) => {
      const id = d[`member:${identifier}:id`] ? d[`member:${identifier}:id`] : d[identifier];
      const name = d[`member:${identifier}:name`] ? d[`member:${identifier}:name`] : d.name;
      const description = d[`member:${identifier}:description`] ? d[`member:${identifier}:description`] : d.description;
      return {
        id: id,
        title: name,
        name: description,
        'member:style:id': d['member:style:id'],
        'member:style:name': d['member:style:name'],
        'member:stylecolor:id': d['member:stylecolor:id'],
        'member:stylecolor:name': d['member:stylecolor:name'],
        stars: d['compositeattributeband'] ? parseInt(d['compositeattributeband'], 10) : 0,
        imageUri: d['attribute:img:id'],
      };
    }), 'id');

    return compData;
  };

  renderCompanionView = (data: BasicPivotItem[]) => {
    const { identifier, defaultCompanionSortField } = this.props;
    const { selectedIndex, companionScrollTo } = this.state;
    const compData = this.generateCompanionViewData(data);
    const sortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === this.state.companionSortField;
    });
    const defaultSortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === defaultCompanionSortField;
    });

    if (compData) {
      const companionProps: CompanionProps = {
        defaultSelection: sortSelection > -1 ? sortSelection : defaultSortSelection,
        sortOptions: this.props.companionSortOptions,
        label: 'Count',
        isDataloaded: this.props.dataLoaded,
        selectedIndex,
        className: companionStyles,
        data: compData,
        noImageUrl: noImage,
        scrollTo: companionScrollTo,
        initialSortDirection: this.state.companionSortDirection,
        isCollapsed: this.state.companionCollapsed,
        onListItemClicked: (identityValue: string) => {
          // TODO: make this logic reusable so it can be invoked during handlePendingCellUpdate if needed
          function findIndexComp(dataKey: string) {
            return compData.findIndex((datum) => {
              const fieldFound = datum[dataKey] ? datum[dataKey] : datum.name;
              return fieldFound === identityValue;
            });
          }
          let key = identifier === 'id' ? 'id' : `member:${identifier}:id`;
          let index = findIndexComp(key);

          key = identifier === 'id' ? 'name' : `member:${identifier}:name`;
          index = index === -1 ? findIndexComp(key) : index;

          const newState = {
            gridScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            selectedId: compData[index].id,
          };
          this.setState(newState);
        },
        onChangeDirection: (direction) => {
          this.setState({
            companionSortDirection: direction,
          });
        },
        onSortSelection: (selection) => {
          this.setState({
            companionSortField: selection.dataIndex,
          });
        },
        onToggleCollapse: (isCollapsed) => {
          this.setState({ companionCollapsed: isCollapsed });
        },
      };
      return <CompanionListView {...companionProps} />;
    } else {
      return <div />;
    }
  };

  handleChangeGroupByDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { groupByDropdownProps, setGroupBySelection } = this.props;
    if (isNil(groupByDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(groupByDropdownProps.options, (option) => {
      return newValue !== null && option.text.substr(0, 16) === newValue.substr(0, 16);
    });
    setGroupBySelection({
      selectedIndex: valueIndex,
      option: groupByDropdownProps.options[valueIndex],
    });
    // TODO move this down
    // if (this.gridApi) {
    //   this.gridApi.deselectAll();
    // }
  };

  handleChangeFloorsetDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { floorsetDropdownProps, setFloorsetSelection } = this.props;
    if (isNil(floorsetDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(floorsetDropdownProps.options, (option) => {
      return option.text === newValue;
    });
    const selection = floorsetDropdownProps.options[valueIndex];
    setFloorsetSelection(selection.text);
  };

  generatePublishedText = (data: BasicPivotItem | undefined, configuratorViewDefn: any) => {
    if (isNil(data) || isNil(configuratorViewDefn)) {
      return [];
    }
    let publishText: string[] = [];
    if (configuratorViewDefn.topAttributes) {
      publishText = configuratorViewDefn.topAttributes.left.map((config: TopAttribute) => {
        const mask = config.mask;
        const renderer = Renderer[config.renderer];
        const styles = config.classes;
        // We do this to prevent screen from crashing when fields are missing from data
        try {
          const value = renderer && mask && styles ? Renderer[config.renderer](data, mask, styles) : renderer && mask ? Renderer[config.renderer](data, mask) : '';
          return value;
        } catch (e) {
          console.warn(`Error building template for left text. \n ${e}`);// eslint-disable-line no-console
          return '';
        }
      });
    }

    return publishText;
  };

  getArrowFromValue = (value: number) => {
    // Math.sign return 1 for positive, 0 for 0, and -1 for negative
    switch (Math.sign(value)) {
      case 1:
      case 0:
        return ARROWDIRECTIONS.UP;
      case -1:
      default:
        return ARROWDIRECTIONS.DOWN;
    }
  };

  getActiveFloorset = (): string => {
    const { floorsetDropdownProps } = this.props;

    if (isNil(floorsetDropdownProps)) {
      return '';
    }

    const selection = floorsetDropdownProps.selection || 0;
    return floorsetDropdownProps.options[selection].dataIndex;
  };

  getSelectedRows = (): PivotBasicItem[] => {
    if (this.gridApi == null) return [];
    const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
    const nodesAfterFilter: IRowNode[] = [];
    this.gridApi.forEachNodeAfterFilter((n) => {
      nodesAfterFilter.push(n);
    });

    // Filter the selected nodes based on column filters in the filter model
    const nodesIntersection = intersection(selectedNodes, nodesAfterFilter);

    const floorsetId = this.getActiveFloorset();
    return nodesIntersection
      .filter((n) => {
        return this.hasSelectableLevels() ? true : isNil(n.allChildrenCount) || n.allChildrenCount <= 0;
      })
      .map((n) => {
        // groupRows, (present when using `selectableLevels`) don't have `node.data`, so we change that to {},
        // if this breaks something somewhere, I'm sorry
        // Mark Todd
        const rowData = n.data ?? {};
        rowData.floorset = floorsetId;
        return rowData;
      });
  };

  submitGlobalUpdate = async ({ dataIndex, value }: Omit<MassColumnUpdateParams, 'value'> & { value: unknown }) => {
    // This fun mess removes that silly wrapped colon stuff (eg: attribute:<x>:id)
    // This is the more concise version: .replace(/(?:^.*?:)?([^:]*)(?::.*)?/, '$1')
    const key = dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    // TODO: may need to devise a more configurable way for setting up the coordinateMap
    // when not reliant on specific view items
    const coordinates = [
      {
        product: this.props.scopeSelection.productMember || '',
        location: this.props.scopeSelection.locationMember || '',
      },
    ];
    const payload: CoarseEditPayload = {
      coordinates,
      [dataIndex]: value,
    };

    let calcValue = value;
    if (isBoolean(value)) {
      calcValue = value ? 'true' : '';
    }
    payload[key] = calcValue;
    return await ServiceContainer.pivotService.coarseEditSubmitData(payload);
    // await fetch new value set & merge
  };

  publishAssortment = async (actionType?: 'publish' | 'unpublish') => {
    if (!actionType) return;
    const selectedItems = this.getSelectedRows();

    const publishAttribute = this.props.publishAttribute || IS_PUBLISHED;
    if (isEmpty(selectedItems)) {
      return;
    }
    let coordMap: {
      [s: string]: string;
    };
    if (this.props.updateCoordinateMap != null) {
      coordMap = this.props.updateCoordinateMap;
    } else if (this.props.massEditConfig != null) {
      coordMap = this.props.massEditConfig.coordinateMap;
    } else {
      logError(`Cannot update dc_publish. Somehow set to generic update without updateCoordinateMap property.`, null);
      return;
    }
    const coordinates = selectedItems.map((rowData) =>
      omitBy(
        mapValues(coordMap, (v) => {
          const value = rowData[v];
          return value;
        }),
        isNil
      )
    );

    if (this.props.submitPayload) {
      const payload = {
        coordinates,
        [publishAttribute]: actionType === 'publish' ? 1 : 0,
      };
      await this.props.submitPayload(payload).then(() => {
        this.setState({
          notificationModal: false,
        });
        this.gridApi.deselectAll();
      });
    }
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.state.notificationModal}
        descriptionText={`Are you sure you wish to ${this.state.actionType}?`}
        onConfirm={async () => {
          try {
            await this.publishAssortment(this.state.actionType);
          } catch (e) {
            toast.error('Failed To Publish');
            ServiceContainer.loggingService.error('Failed To Publish');
          }
        }}
        onCancel={() => {
          this.gridApi.deselectAll();
          this.setState({
            notificationModal: false,
          });
        }}
      />
    );
  };

  handleConfigurablePostAction = (updateType: 'coarse' | 'granular', value: unknown, dataIndex: string) => {
    // for now, assuming will always be department (not location) and coarse (not granular)?

    const update = {
      nodes: (this.getSelectedRows() as unknown) as BasicPivotItem[],
      dataIndex,
      value,
    };

    this.submitGlobalUpdate(update)
      .catch((error) => {
        toast.error(`${(error as any).message}`);
      })
      .finally(() => {
        this.props.onRefreshConfigurableGridData();
      });
  };

  generateSubheaderExtraDropdownProps(): SubheaderDropdownProps[] {
    const { groupByDropdownProps, floorsetDropdownProps } = this.props;
    let dropdowns: SubheaderDropdownProps[] = [];

    if (!isNil(groupByDropdownProps)) {
      dropdowns = concat(dropdowns, {
        ...groupByDropdownProps,
        qaKey: 'Group By',
        handleChangeOnDropdown: this.handleChangeGroupByDropdown,
      });
    }

    if (!isNil(floorsetDropdownProps)) {
      dropdowns = concat(dropdowns, {
        ...floorsetDropdownProps,
        dataQa: 'floor-sets',
        handleChangeOnDropdown: this.handleChangeFloorsetDropdown,
      });
    }

    return dropdowns;
  }

  generateSubheaderExtraActionButtonProps(): SubheaderActionButtonProps {
    const { dataLoaded, massEditConfig, configurablePostAction } = this.props;

    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
        config: massEditConfig,
        title: massEditConfig.title,
        handleSubmit: () => {
          this.gridApi?.deselectAll();
          this.props.onRefreshConfigurableGridData();
        },
        getSelectedItems: () => this.getSelectedRows(),
        handleCancel: () => this.gridApi?.deselectAll(),
        gridApi: this.gridApi,
        dataLoading: this.state.gridRdyCnt === 0,
      };

    const postAction = isEmpty(configurablePostAction)
      ? undefined
      : {
        action: formatConfigurableActionItem(configurablePostAction, this.handleConfigurablePostAction),
        dataLoading: !dataLoaded,
      };

    return {
      massEdit,
      postAction,
    };
  }

  onGraphItemClick = (data: unknown) => {
    const { configuratorViewDefn, floorsetDropdownProps } = this.props;
    const timeDropdownPresent = !isNil(floorsetDropdownProps);
    const ignoreClicks = this.props.configuratorViewDefn.graph?.ignoreClicks;
    if (ignoreClicks || timeDropdownPresent) {
      return;
    }

    const dataIndex = configuratorViewDefn.graph?.timeDataIndex;
    const nextTime: string = (data as { [key: string]: string })[dataIndex];
    this.props.setFloorsetSelection(nextTime);
  };
  updateSelectedItem = (event: RowSelectedEvent) => {
    const { node } = event
    if (node.parent && !isNil(node.parent.childrenAfterFilter)) {
      this.debouncedRenderUpdate();
    }
  }
  debouncedRenderUpdate = debounce(() => {
    this.forceUpdate();
  }, 100);

  handleItemClick = (selections: BasicPivotItem | BasicPivotItem[]) => {
    const canAddToCart = !isNil(this.props.cartItemType);
    if (canAddToCart) {
      this.props.toggleSelections(selections);
    }
  }

  handlePopoverItemClick = (item: BasicPivotItem) => {
    // pass through to popover HOC to handle
    if (this.props.onItemClicked) {
      this.props.onItemClicked(item);
    }
  }

  /** This function makes several assumptions about the shape of the data that it needs to create it's scopes.
   * This is due to the unique nature of this configuration.
   * Notably it presumes grouped columns with children that have real data in them, which it requires in order to map
   * to mfp scope anchors.
   */
  createMfpScope = (button: ConfigurableGridButton) => {
    const selectedItems = this.gridApi.getSelectedNodes();
    if (button.type !== 'multiSelectCreateMfpScope') { return; }
    const coordMap = button.params.coordinateMap;
    const selectedItemsFirstChildren = selectedItems.flatMap(groupRow => groupRow.allLeafChildren[0].data)

    const coordinates = mapValues(coordMap, (v) => {
      return selectedItemsFirstChildren.map(i => i[v])
    })
    this.setState({
      isMfpScopeLoading: true
    }, () => {
      // FIXME get this type right
      // @ts-ignore
      this.props.createMfpScope(coordinates, button.params.coordinateMap.workflow).then(() => {
        if(this.props.setPreviousView){
          this.props.setPreviousView(this.props.routerLocation.pathname)
        }
        this.props.routerNavigate(button.params.path);
      }).catch((_e)=> {
        toast.error(`Failed To ${button.text}`);
        ServiceContainer.loggingService.error(`Failed To ${button.text}`);
      }).finally(() => {
        this.setState({isMfpScopeLoading: false})
      })
    })
  }

  handleIsRowSelectable = (row: IRowNode): boolean => {
    const selectableLevels = this.props.actions?.buttons?.flatMap((btn) => {
      return btn.selectableLevels;
    }).filter(sl => !isNil(sl))
    if (this.shouldEnableCheckboxSelection() && isEmpty(selectableLevels)) return true;
    // FIXME this is bodged in for now and I don't prefer it actually work like this
    const agGroupingField = row.field || '';
    const ret = selectableLevels?.includes(agGroupingField);
    return ret || false;
  }

  createCustomActionModal = () => {
    const { actions } = this.props;
    const { actionPopupInd } = this.state;
    const buttons = actions?.buttons || [];
    const curAction = get(buttons, actionPopupInd ?? -1);

    if (curAction?.type !== 'multiSelectPost') { return }

    return <ActionModal
      onToggleModal={(togType) => {
        if (togType === 'apply') {
          this.props.onRefreshConfigurableGridData();
        }
        this.setState({
          actionPopupActive: false,
          actionPopupInd: undefined
        })
      }}
      isOpen={!!this.state.actionPopupActive}
      selectedItems={this.getSelectedRows()}
      config={curAction}
    />
  }

  shouldEnableCheckboxSelection = (): boolean => {
    const { massEditConfig, showPublish, cartItemType, actions, allowWorklistFunctionality = false, columnDefs } = this.props;
    const haveHeaditors = columnDefs.some((cd) => cd.useColumnHeaderMassEditUpdate === true);
    return !isNil(massEditConfig) || showPublish || !isNil(cartItemType) || !isEmpty(actions?.buttons) || allowWorklistFunctionality || haveHeaditors;
  }

  /** Checks whether any `viewConfig.actions.buttons` has the `selectableLevels` property, which changes how row selection works */
  hasSelectableLevels = (): boolean => {
    return !isEmpty(this.props.actions?.buttons?.flatMap((btn) => {
      return btn.selectableLevels;
    }).filter(sl => !isNil(sl)));
  }

  //#region SplitButton Menu Item Functions

  getPublishMenuItems = (): MenuItemDef[] => {
    const { publishText, showPublish, hideUnpublish = false } = this.props;
    const publishMenuItems: MenuItemDef[] = [];

    if (showPublish) {
      const menuText = publishText ?? 'Publish';
      publishMenuItems.push({
        name: menuText,
        action: () => {
          this.setState({
            actionType: 'publish',
            notificationModal: true,
          });
        },
        icon: 'fal fa-plus-circle',
        tooltip: `${menuText} selected items`,
      });

      if (!hideUnpublish) {
        publishMenuItems.push({
          name: 'Unpublish',
          action: () => {
            this.setState({
              actionType: 'unpublish',
              notificationModal: true,
            });
          },
          icon: 'fal fa-minus-circle',
          tooltip: 'Unpublish selected items',
        });
      }
    }

    return publishMenuItems;
  }

  getWorklistMenuItems = (): MenuItemDef[] => {
    const { allowWorklistFunctionality = false, identifier} = this.props;
    let worklistMenuItems: MenuItemDef[] = [];

    if (allowWorklistFunctionality) {
      worklistMenuItems = concat(worklistMenuItems, getWorklistMultiselectMenuItems(identifier, () => this.gridApi, {
        onPostAction: () => this.gridApi?.deselectAll(),
      }));
    }

    return worklistMenuItems;
  }

  getCustomActionMenuItems = (): MenuItemDef[] => {
    const { actions } = this.props;
    const buttons = actions?.buttons || [];

    const buttonTypeToCallback = (type: string, index: number): () => void => {
      switch (type) {
        // FIXME: get this string from a const from general.ts
        case 'multiSelectCreateMfpScope':
          // TODO this needs to roll into a loading state somehow

          return () => {
            this.createMfpScope(buttons[index]);
          };
        // FIXME: get this string from a const from general.ts
        case 'multiSelectPost':
          return () => {
            this.setState({
              actionPopupActive: true,
              actionPopupInd: index
            })
          }
        default:
          return () => {
            this.setState({
              actionPopupActive: true,
              actionPopupInd: index
            })
          }
      }
    }

    return buttons.map((btn, ind) => {
      return {
        name: btn.text,
        tooltip: btn.tooltip ?? btn.text,
        action: buttonTypeToCallback(btn.type, ind),
        icon: iconClassToString(`${btn.icon ?? 'fal fa-bars'}`),
      }
    })
  }

  getSplitButtonMenuItems = (): MenuItemDef[] => {
    const worklistMenuItems = this.getWorklistMenuItems();
    const publishMenuItems = this.getPublishMenuItems();
    const customActionMenuItems = this.getCustomActionMenuItems();
    return concat(worklistMenuItems, publishMenuItems, customActionMenuItems);
  }

  //#endregion

  render() {
    const {
      title,
      viewDefnState,
      dataLoaded,
      showFlowStatus,
      massEditConfig,
      showPublish,
      configuratorViewDefn,
      unmodifiedViewDefn,
      defaultCompanionSortField,
      groupByDropdownProps,
      topAttributesData,
      data,
      leafIdProp,
      dependentCalcs,
      dataApi,
      adornments,
      salesAdjustmentConfig,
      clientActionHandlers,
      updateCoordinateMap,
      groupBySelection,
      configureSelections,
      topMembers,
      isWorklistActive,
      showLookBackPeriod
    } = this.props;

    if (!isViewDefnLoaded(viewDefnState)) {
      return <Overlay type="loading" visible={true} />;
    }

    const { gridScrollTo, activeStyleColor } = this.state;

    const viewConfigurator = this.onUpdateConfig &&
      configuratorViewDefn &&
      unmodifiedViewDefn && {
      viewConfig: configuratorViewDefn,
      configurationSelections: this.props.configureSelections,
      unmodifiedViewDefn: unmodifiedViewDefn,
      updateConfig: this.onUpdateConfig,
      showPinCheckboxForGrid: true,
      companionData: {
        companionSortDirection: this.state.companionSortDirection,
        companionCollapsed: this.state.companionCollapsed,
        companionSortField: this.state.companionSortField,
      },
      defaultCompanionData: {
        companionSortDirection: 'desc',
        companionCollapsed: false,
        companionSortField: defaultCompanionSortField,
      },
    };

    const subheaderProps: SubheaderOwnProps = {
      title: title || '',
      showFlowStatus,
      showLookBackPeriod,
      summary: '',
      showSearch: true,
      extraDropdowns: this.generateSubheaderExtraDropdownProps(),
      extraActionButtons: this.generateSubheaderExtraActionButtonProps(),
      downloadLink: this.props.subheader?.downloadLink,
      errorCondition: this.props.subheaderErrorText,
      viewConfigurator,
      favoritesSaveOverride: {
        groupBySelection: groupByDropdownProps?.selection as number | undefined,
      },
    };
    let configureModalProps: ConfigureModalProps;
    if (!isNil(this.props.configureOptions)) {
      const { configureIsOpen } = this.state;
      subheaderProps.configureOptions = {
        type: 'enabled',
        onConfigureClick: () => {
          this.setState({
            configureIsOpen: true,
          });
        },
      };
      const configureOptions = this.props.configureOptions;
      const updateSelections = this.props.updateConfigureSelections || noop;
      configureModalProps = {
        enabled: true,
        isOpen: !!configureIsOpen,
        ...this.props.configureOptions,
        selections:
          this.state.currentConfigureSelections || this.props.configureSelections || configureOptions.defaultSelections,
        onReset: () => {
          this.setState({
            currentConfigureSelections: configureOptions.defaultSelections || [],
          });
        },
        onToggleModal: (action) => {
          if (isNil(this.state.currentConfigureSelections)) {
            return;
          }
          // this.updateConfigureSelections(configureLastSelected);
          const nextState: Pick<ConfigurableGridState, 'configureIsOpen' | 'currentConfigureSelections'> = {
            configureIsOpen: false,
          };
          switch (action) {
            case 'apply': {
              updateSelections(this.state.currentConfigureSelections);
              break;
            }
            default: {
              nextState.currentConfigureSelections = this.props.configureSelections;
            }
          }
          this.setState(nextState);
        },
        selectionUpdate: (selections: Option[]) => {
          this.setState({
            currentConfigureSelections: selections,
          });
        },
      };
    } else {
      configureModalProps = { enabled: false };
    }

    if (data && data[0]) {
      set(data[0], 'floorset', this.getActiveFloorset());
    }

    const editableGridOptions: EditableGridProps = {
      data: this.props.data,
      columnDefs: this.props.columnDefs,
      gridScrollTo: gridScrollTo,
      gridRowHeight: this.props.gridRowHeight,
      groupRowHeight: this.props.groupRowHeight,
      handleGridReady: (params: GridReadyEvent) => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.setState({ gridRdyCnt: this.state.gridRdyCnt + 1 });
      },
      onCellClicked: (event: CellClickedEvent) => {
        const { identifier } = this.props;
        if (event && event.data && !event.data.$$GroupHeader) {
          let key = identifier === 'id' ? 'stylecolor' : `member:${identifier}:id`;
          const identityValue = event.data[key];
          const companionData = this.generateCompanionViewData(this.props.data);

          const index = companionData
            ? companionData.findIndex((datum: ListViewable) => {
              const fieldFound = datum[key] ? datum[key] : datum.name;
              // Check if the field is present as a member
              if (datum[`member:${key}:id`] == identityValue) {
                key = `member:${key}:id`;
                return true;
              }
              return fieldFound === identityValue;
            })
            : event.data[key];

          this.setState({
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            selectedId: companionData[index].id,
            activeStyleColor: event.data['stylecolor'],
          });
        }
      },
      activeFloorset: this.getActiveFloorset(),
      // drilled props
      redecorateMap: this.props.configuratorViewDefn.redecorateMap,
      validValuesCache: this.state.validValuesCache,
      leafIdProp,
      topMembers,
      configLoaded: isViewDefnLoaded(viewDefnState),
      activeStyleColor,
      dataLoaded,
      dependentCalcs,
      dataApi,
      adornments,
      salesAdjustmentConfig,
      showPublish,
      clientActionHandlers,
      massEditConfig,
      updateCoordinateMap,
      groupByDropdownProps,
      groupBySelection,
      configureSelections,
      // the last check of these may not be always correct.  If we have an mfpCreateScope button then we need it without all the other options,
      // but usually one of the other checks covers the other multi select option
      enableCheckboxSelection: this.shouldEnableCheckboxSelection(),
      onItemClicked: this.handleItemClick,
      onPopoverItemClicked: this.handlePopoverItemClick,
      onRowSelected: this.updateSelectedItem,
      isRowSelectable: this.handleIsRowSelectable,
      noRowsOverlayConfig: this.props.configuratorViewDefn.noRowsOverlay
    };

    // TODO: continue to abstract these extraRow components to their own components out of this file
    // to keep slimming this file down (linecount/logic)

    const testArrowValue = (num: number) => {
      if (num > 100000 || num < -100000) {
        return '~';
      }
      if (!isNumber(num)) {
        return '';
      }
      return num;
    };

    let arrowValueGroup: ArrowValueRendererProps[] = [];
    if (topAttributesData && unmodifiedViewDefn && unmodifiedViewDefn.topAttributes) {
      arrowValueGroup = (unmodifiedViewDefn.topAttributes.right as TopAttribute[]).map((config) => {
        const { text = '', dataIndex, renderer } = config;
        const valueRenderer = Renderer[renderer];
        const testValue = testArrowValue(topAttributesData[dataIndex]);
        const finalValue = valueRenderer ? Renderer[renderer](testValue) : testValue;
        return {
          header: text,
          value: finalValue.toString(),
          arrowDirection: this.getArrowFromValue(topAttributesData[dataIndex]),
        };
      });
    }


    const splitButtonMenuItems = this.getSplitButtonMenuItems();
    const maybePublishText = this.props.showPublishText && !isEmpty(this.generatePublishedText(topAttributesData, unmodifiedViewDefn));
    const selectedId = topMembers || this.state.selectedId || '';
    const showExtraRow = !isEmpty(arrowValueGroup) || maybePublishText;
    const topExpanded = isNil(configuratorViewDefn.topAttributes?.expanded)
      ? true
      : configuratorViewDefn.topAttributes.expanded;

    const splitButtonActions = <div>{!isEmpty(splitButtonMenuItems) && (
      <SplitButton
        text={splitButtonMenuItems[0].name}
        icon={splitButtonMenuItems[0].icon as string}
        isLoading={this.state.isMfpScopeLoading}
        isDisabled={this.getSelectedRows().length === 0}
        menuItems={splitButtonMenuItems.slice(1)}
        onButtonClick={splitButtonMenuItems[0].action ?? noop}
      />
    )}

    </div>
    return (
      <div className={listPairStyle}>
        <Subheader {...subheaderProps} />
        <div className="data-container">
          {this.props.hideCompanion === true ? null : this.renderCompanionView(data)}
          <div className="content-container">
            <div className={extraRowContainerStyles}>
              {!isNil(configuratorViewDefn.graph) ? (
                <StyledAccordion
                  title={configuratorViewDefn.graph.title || 'Visualize'}
                  expanded={configuratorViewDefn.graph.expanded}
                >
                  <div style={{ minHeight: `${configuratorViewDefn.graph.height}px`, width: '100%' }}>
                    <MetricLineGraph
                      defnId={configuratorViewDefn.graph.configApi.params?.defnId || ''}
                      selectionRequired={isWorklistActive}
                      selectedId={selectedId}
                      height={configuratorViewDefn.graph.height}
                      timeDataIndex={configuratorViewDefn.graph.timeDataIndex}
                      onItemClick={this.onGraphItemClick}
                    />
                  </div>
                </StyledAccordion>
              ) : null}
              {(showExtraRow || !isEmpty(splitButtonMenuItems)) && <div className={marcosRowAndButtonContainer}>
                {showExtraRow && (
                  <StyledAccordion
                    title={configuratorViewDefn.topAttributes.title || 'Macros'}
                    expanded={topExpanded}

                  >
                    <div className={macrosContainer}>
                      {/* TODO: abstract these to separate components  */}
                      {maybePublishText && (
                        <div className={styles.publishedContainer}>
                          {this.generatePublishedText(topAttributesData, unmodifiedViewDefn).map((text: string) => {
                            return <span key={text}>{text}</span>;
                          })}
                        </div>
                      )}
                      <div className={styles.arrowGroup}>
                        {arrowValueGroup.map((config) => {
                          return <ArrowValueRenderer {...config} key={config.header} />;
                        })}
                      </div>
                    </div>
                  </StyledAccordion>
                )}
                <div className={buttonMacroContainer}>
                  {splitButtonActions}
                </div>
              </div>}
            </div>
            <div className={gridContainerStyle}>
              <EditableGrid {...editableGridOptions} />
            </div>
          </div>
        </div>

        {this.renderConfirmationModal()}
        <ConfigureModal {...configureModalProps} />
        {this.createCustomActionModal()}
      </div>
    );
  }
}
